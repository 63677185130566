import React from 'react'
import Lottie from 'react-lottie-player'
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import { PRELOADER_JSON_URL } from './constants';

export default function Preloader() {
  return (
    <Lottie
      loop
      path={PRELOADER_JSON_URL}
      play
      style={{ height: '90%' }}
    />
  )
}