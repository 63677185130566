import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import gamePhone from '../../../../images/game-phone.svg';

import './styles.scss';

const HowItWorks = () => {
    const { t } = useTranslation();

    return (
        <div className='howItWorkWrapper'>
            <div className='gradient' />
            <div className='howItWorkContainer'>
                <div className='howItWorkHeaderContainer'>
                    <AnimationOnScroll offset={120} animateIn="animate__fadeInLeftBig" animateOnce>
                        <div className='mainHeader'>
                            <span className='blueDecoratedText'>{t('how_it')}</span>
                            <span className='whiteDecoratedText'> {t('works')}</span>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll offset={170} animateIn="animate__fadeInRightBig" animateOnce>
                        <div className='howItWorkDescriptionContainer'>
                            <div className='description'>
                                {t('how_it_works_part_one')}
                                {"\n\n"}
                                {t('how_it_works_part_two')}
                                {"\n\n"}
                                {t('how_it_works_part_three')}
                                {"\n\n"}
                                {t('how_it_works_part_four')}
                                {"\n\n"}
                                {t('how_it_works_part_five')}
                                <AnimationOnScroll offset={190} animateIn="animate__fadeInLeftBig" animateOnce>
                                    <div className='screenGameContainer'>
                                        <img src={gamePhone} alt='gamePhone' />
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
