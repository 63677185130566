import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ReactComponent as GamePhone } from '../../../../images/game-phone.svg';

import './styles.scss';

const HowItWorks = () => {
    const { t } = useTranslation();

    return (
        <div className='howItWorkWrapper'>
            <div className='gradient' />
            <div className='howItWorkMobileContainer'>
                <div className='leftBlock'>
                    <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce>
                        <div className='mainHeader'>
                            <span className='blueDecoratedText'>{t('how_it')}</span>
                            <span className='whiteDecoratedText'> {t('works')}</span>
                        </div>
                        <div className='description'>
                            {t('how_it_works_part_one')}
                            {"\n\n"}
                            {t('how_it_works_part_two')}
                            {"\n\n"}
                            {t('how_it_works_part_three')}
                            {"\n\n"}
                            {t('how_it_works_part_four')}
                            {"\n\n"}
                            {t('how_it_works_part_five')}
                        </div>
                    </AnimationOnScroll>
                </div>
                <div className='rightBlock'>
                    <AnimationOnScroll offset={190} animateIn="animate__fadeInRightBig" animateOnce>
                        <div className='carImageContainer'>
                            <GamePhone />
                        </div>
                    </AnimationOnScroll>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
