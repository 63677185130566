import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import telegram from '../../../../images/telegram.png';

import Button from '../Button';

import { ReactComponent as Logo } from '../../../../images/logo.svg';
import { ReactComponent as User } from '../../../../images/user.svg';
import { SECTIONS } from '../../Landing';

import './styles.scss';

export default ({ showButton = false }) => {
  const { t } = useTranslation('landing');
  const navigate = useNavigate();
  const handleSigninClick = useCallback(() => navigate('/signin'), [navigate]);

  return (
    <nav className="navContainer">
      <div className="content">
        <div className="leftBlock">
          <Logo
            className="logo"
          />
          <li className='socialContainer'>
            <div onClick={() => window.open('https://t.me/wheelium', '_blank')} className="social">
              <span><img src={telegram} /> </span> <span>Wheelium</span>
            </div>
          </li>
        </div>

        <div className="rightBlock">
        <ul>
            <li>
              <a href={`#${SECTIONS.HOW_IT_WORKS}`}>{t('how_it_works')}</a>
            </li>
            <li>
              <a href={`#${SECTIONS.WITHDRAW}`}>{t('withdraw')}</a>
            </li>
            <li>
              <a href={`#${SECTIONS.GAME_MODES}`}>{t('game_modes')}</a>
            </li>
            <li>
              <a href={`#${SECTIONS.ROADMAP}`}>{t('roadmap')}</a>
            </li>
          </ul>

          {showButton && (
            <Button
              onClick={handleSigninClick}
              text={t('Login')}
              color="white"
              type="smallIcon"
              icon={<User />}
            />
          )}
        </div>
      </div>
    </nav>
  );
};
