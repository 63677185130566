import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../Logo';
import LanguageSwitcher from '../LanguageSwitcher';
import { ReactComponent as MenuIcon } from '../../../../images/menuIcon.svg';

import { SECTIONS } from '../../Landing';

import './styles.scss';

export default ({ onOpenDrawer }) => {
  const { t } = useTranslation('landing');

  return (
    <div className="landingHeaderContainer">
      <div className="landingLogoWrapper">
        <MenuIcon
          className="landingLamburgerMenu"
          onClick={onOpenDrawer}
        />
      </div>
      <Logo />
      <div className="landingHeaderMenu">
        <ul>
          <li>
            <a href={`#${SECTIONS.HOW_IT_WORKS}`}>{t('how_it_works')}</a>
          </li>
          <li>
            <a href={`#${SECTIONS.WITHDRAW}`}>{t('withdraw')}</a>
          </li>
          <li>
            <a href={`#${SECTIONS.GAME_MODES}`}>{t('game_modes')}</a>
          </li>
          <li>
            <a href={`#${SECTIONS.ROADMAP}`}>{t('roadmap')}</a>
          </li>
        </ul>

        <div className='switcherContainer'>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};
