import React, { useState, useMemo } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Footer from './components/Footer';
import Welcome from './components/Welcome';
import Header from './components/Header';
import About from './components/About';
import HowItWorks from './components/HowItWorks';
import GameModes from './components/GameModes';
import Drawer from './components/Drawer';
import MobileMenu from './components/MobileMenu';

import './styles.scss';
import StartSteps from './components/StartSteps';
import RoadMap from './components/RoadMap';
import { ReactComponent as Game } from '../../images/game-phone-list.svg';
import { MOBILE_WIDTH } from '../../constants';

export const SECTIONS = {
  HOW_IT_WORKS: 'howItWorks',
  WITHDRAW: 'withdraw',
  GAME_MODES: 'gameModes',
  ROADMAP: 'roadmap',
};

export default () => {
  const [isMobileDrawerOpened, setIsMobileDrawerOpened] = useState(false);

  const isMobile = useMemo(() => {
    return window?.innerWidth <= MOBILE_WIDTH;
  }, [window?.width]);

  const handleOpenMobileDrawer = () => {
    setIsMobileDrawerOpened(true);
  };

  const handleCloseMobileDrawer = () => {
    setIsMobileDrawerOpened(false);
  };

  return (
    <div className="landingContainer">
      <header className="headerContainer">
        <Header onOpenDrawer={handleOpenMobileDrawer} />
      </header>
      <Welcome />
      <div className="sectionPadding" id={SECTIONS.HOW_IT_WORKS}>
        <HowItWorks />
      </div>
      <div className="sectionPadding">
        <StartSteps />
      </div>
      {
        isMobile && <AnimationOnScroll offset={200} animateIn="animate__fadeInLeftBig" animateOnce>
          <div className='carImageContainer'>
              <Game />
          </div>
        </AnimationOnScroll>
      }
      <div className="sectionPadding" id={SECTIONS.WITHDRAW}>
        <About />
      </div>
      <div className="sectionPadding" id={SECTIONS.GAME_MODES}>
        <GameModes />
      </div>
      <div className="sectionPadding" id={SECTIONS.ROADMAP}>
        <RoadMap />
      </div>
      <footer className="sectionPadding">
        <Footer />
      </footer>
      <Drawer isOpen={isMobileDrawerOpened} onClose={handleCloseMobileDrawer}>
        <MobileMenu onClose={handleCloseMobileDrawer} />
      </Drawer>
    </div>
  );
};
