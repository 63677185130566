import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../../../images/arrow.svg';

import './styles.scss';

const GameModes = () => {
    const { t } = useTranslation();

    return (
        <div className='gameModesContainer'>
            <div className='gameModesHeader'>
                <span className='blueDecoratedText'>{t('game')} </span>
                <span className='whiteDecoratedText'>{t('modes')}</span>
            </div>
            <div className='gameModesItems'>
                <div className='itemContainer yellowBorderShadow yellowBackground'>
                    <div className='itemHeader'>
                        {t('single_player')}
                    </div>
                    <div className='points'>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('study_the_map')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('practice')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('get_experience')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('play_without_risk')}</div>
                        </div>
                    </div>
                </div>
                <div className='itemContainer blueBorderShadow blueBackground'>
                    <div className='itemHeader'>
                        {t('head_to_head')}
                    </div>
                    <div className='points'>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('create_or_find_a_race')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('set_the_amount')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('choose_a_map')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('invite_your_friend')}</div>
                        </div>
                    </div>
                </div>
                <div className='itemContainer redBorderShadow redBackground'>
                    <div className='itemHeader'>
                        {t('multiplayer')}
                    </div>
                    <div className='points'>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('play_with_friends')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('group_races_up_to_ten_pilots')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('get_into_the_top_3')}</div>
                        </div>
                        <div className='point'>
                            <div>
                                <Arrow />
                            </div>
                            <div>{t('win_the_prize_pool')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameModes;
