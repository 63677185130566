import React from 'react';

import './styles.scss';

export const BUTTON_TYPES = {
  DEFAULT: 'default',
  SMALL_ICON: 'smallIcon',
  LARGE_ICON: 'largeIcon',
};
export const BUTTON_COLORS = { WHITE: 'white', ORANGE: 'orange', BLUE: 'blue' };

export default ({
  onClick,
  type = BUTTON_TYPES.DEFAULT,
  color = BUTTON_COLORS.WHITE,
  icon,
  text,
}) => {
  const isSmallIcon = type === BUTTON_TYPES.SMALL_ICON && icon;
  const isLargeIcon = type === BUTTON_TYPES.LARGE_ICON && icon;

  return (
    <button className={`button ${type} ${color}`} onClick={onClick}>
      {isLargeIcon && <img src={icon} className="icon" />}
      <div className="landingButtonContent">
        {isSmallIcon && <img src={icon} className="icon" />}
        <span>{text}</span>
      </div>
    </button>
  );
};
