import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation('landing');

    return (
        <div className="itemsContainer">
            <div className="curved-line"></div>
            <div className="points">
                <div className="item">
                    <div className="pointContainer">
                    <div className="done">
                        <span>09/22</span>
                    </div>
                    </div>
                    <div className="itemText">{t('roadmap_launch_site')}</div>
                </div>
                <div className="item">
                    <div className="pointContainer">
                    <div className="done">
                        <span>10/22</span>
                    </div>
                    </div>
                    <div className="itemText">{t('roadmap_organization_of_advertising_campaign')}</div>
                </div>
                <div className="item">
                    <div className="pointContainer">
                    <div className="itemPoint">
                        <span>12/22</span>
                    </div>
                    </div>
                    <div className="itemText">{t('roadmap_minimal_viable_product_development')}</div>
                </div>
                <div className="item">
                    <div className="pointContainer">
                    <div className="itemPoint">
                        <span>01/23</span>
                    </div>
                    </div>
                    <div className="itemText">{t('roadmap_alpha_version_testing')}</div>
                </div>
                <div className="item">
                    <div className="pointContainer">
                        <div className="itemPoint">
                            <span>02/23</span>
                        </div>
                    </div>
                    <div className="itemText">{t('roadmap_game_launch')}</div>
                </div>
            </div>
        </div>
    );
}
