import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FlyCar } from '../../../../images/flycar.svg';
import { ReactComponent as Cup } from '../../../../images/cup.svg';
import { ReactComponent as Coins } from '../../../../images/coins.svg';

import './styles.scss';

export default () => {
    const { t } = useTranslation();

    const items = [{
        Icon: FlyCar,
        header: t('top_up_account'),
    }, {
        Icon: Cup,
        header: t('win_the_race'),
    }, {
        Icon: Coins,
        header: t('get_your_money'),
    }];

    return (
        <div className='stepsContainer'>
            {items.map(({ Icon, header }) => {
                return (
                    <div className='stepContainer'>
                        <div className='stepIcon'><Icon /></div>
                        <div className='stepHeader'>{header}</div>
                    </div>
                )
            })}
    </div>
    );
};
