import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import uaIcon from '../../../../images/uaIcon.png';
import usaIcon from '../../../../images/usaIcon.png';

import './styles.scss';

const ENGLISH = 'en';
const UKRANIAN = 'ua';

const LANGUAGES = [
  { lang: ENGLISH, img: usaIcon },
  { lang: UKRANIAN, img: uaIcon }
];

export default () => {
  const { t, i18n } = useTranslation();

  const language = LANGUAGES.find(l => l.lang === i18n.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [languages, setLanguages] = useState(LANGUAGES);
  const [showLanguageList, setShowLanguageList] = useState(false);

  const changeLanguage = async (lang) => {
    const language = LANGUAGES.find(l => l.lang === lang);
    console.log(language)
    await i18n.changeLanguage(language.lang);
    setCurrentLanguage(language);
    setShowLanguageList(false);
  }
  return (
    <div className="languageSwitcherContainer">
      <img
        src={currentLanguage.img}
        className="languageImage"
        onClick={() => setShowLanguageList(true)}
      />
      {showLanguageList ? (
        <>
          {languages.filter(l => l.lang !== currentLanguage.lang).map(l => {
            return (
              <div className='flagContainer'>
                <img src={l.img} alt='langIcon' className="secondaryLanguageImage" onClick={async () => await changeLanguage(l.lang)}/>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};
