import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../Logo';
import { ReactComponent as CloseIcon } from '../../../../images/closeIcon.svg';
import { SECTIONS } from '../../Landing';
import './styles.scss';
import telegram from '../../../../images/telegram.png';

export default ({ onClose }) => {
  const { t } = useTranslation('landing');

  return (
    <div className="landingMobileMenu">
      <div className="mobileMenuHeader">
        <Logo />
        <CloseIcon
          width={20}
          height={20}
          style={{ cursor: 'pointer' }}
          onClick={onClose}
        />
      </div>
      <ul>
        <li>
          <a onClick={onClose} href={`#${SECTIONS.HOW_IT_WORKS}`}>{t('how_it_works')}</a>
        </li>
        <li>
          <a onClick={onClose} href={`#${SECTIONS.WITHDRAW}`}>{t('withdraw')}</a>
        </li>
        <li>
          <a onClick={onClose} href={`#${SECTIONS.GAME_MODES}`}>{t('game_modes')}</a>
        </li>
        <li>
          <a onClick={onClose} href={`#${SECTIONS.ROADMAP}`}>{t('roadmap')}</a>
        </li>
        <li className='socialContainer'>
          <div onClick={() => window.open('https://t.me/wheelium', '_blank')} className="social">
            <span><img src={telegram} /> </span> <span>Wheelium</span>
          </div>
        </li>
      </ul>
    </div>
  );
};
