import React, { useMemo } from 'react';
import { MOBILE_WIDTH } from '../../../../constants';
import Mobile from './AboutMobile';
import Desktop from './AboutDesktop';

export default () => {
    const isMobile = useMemo(() => {
        return window?.innerWidth <= MOBILE_WIDTH;
    }, [window?.width]);

    const Component = isMobile ? Mobile : Desktop;

    return <Component />;
};