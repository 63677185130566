import React, { useTransition } from 'react';
import MobileRoadMap from './MobileRoadMap';

import { useTranslation } from 'react-i18next';

import './styles.scss';

export default () => {
    const { t } = useTranslation();
    return (
        <div className='roadmapContainer'>
            <MobileRoadMap />
            <div className='roadmapHeader'>
                <span className='blueDecoratedText'>{t('road')}</span>
                <span className='whiteDecoratedText'>{t('map')}</span>
            </div>
        </div>
    );
};