import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Landing from './pages/Landing';
import NotFound from './pages/NotFound';

function App() {
  useEffect(() => {
    console.log('mounted')
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
