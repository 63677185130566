import React, { useEffect, useState } from 'react';
import telegram from '../../../../images/telegram.png';
import play from '../../../../images/play.png';
import Button from '../../../../components/Button';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export default () => {
    const { t } = useTranslation('landing');

    const [playButtonComingSoon, setPlayButtonComingSoon] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPlayButtonComingSoon(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [playButtonComingSoon]);

    return (
        <div id="intro" className='bg-image'>
            <div className='textContainer'>
                <div className='descriptionContainer'>
                    <div className="mainText">
                        Welcome
                    </div>
                    <div className="subText">
                        to Wheelium
                    </div>
                    <div className='subDescriptionContainer'>
                        <div className='description'>
                            {t('drive_to_earn_real_money')}
                        </div>
                        <div className='description'>
                            {t('play_with_real_people')}
                        </div>
                    </div>
                    <div className='mainActions'>
                        <div className='socialItem'>
                            <Button onClick={() => window.open('https://t.me/wheelium', '_blank')} text={t('join_us')} className="blueBorder" icon={telegram} />
                        </div>
                        <div className='socialItem'>
                            <Button onClick={() => setPlayButtonComingSoon(true)} text={playButtonComingSoon ? t('coming_soon') : t('play')} className='blueBorder' icon={playButtonComingSoon ? '' : play} textClassName={`${playButtonComingSoon ? 'comingSoon' : ''}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};