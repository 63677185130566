import React from 'react';

import { ReactComponent as Logo } from '../../../../images/logo.svg';

import './styles.scss';

export default () => {
  return (
    <div className='landingLogoContainer'>
      <Logo />
    </div>
  );
};
