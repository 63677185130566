import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Game } from '../../../../images/game-phone-list.svg';

import './styles.scss';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className='aboutMobileContainer'>
            <div className='leftBlock'>
                <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce>
                    <div className='carImageContainer'>
                        <Game />
                    </div>
                </AnimationOnScroll>
            </div>
            <div className='rightBlock'>
                <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce>
                    <div className='mainHeader'>
                        <span className='blueDecoratedText'>{t('with')}</span>
                        <span className='whiteDecoratedText'>{t('draw')}</span>
                    </div>
                    <div className='description'>
                        {t('withdraw_part_one')}
                        {"\n\n"}
                        {t('withdraw_part_three')}
                        {"\n\n"}
                        {t('withdraw_part_two')}
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default About;
