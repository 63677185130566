import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './styles.scss';

export default () => 
{
    const { t } = useTranslation();

    return (
        <div className='wrapper'>
            <div className='container'>
                <div className='headerContainer'>
                    <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce>
                        <div className='mainHeader'>
                            <span className='blueDecoratedText'>{t('with')}</span>
                            <span className='whiteDecoratedText'>{t('draw')}</span>
                        </div>
                    </AnimationOnScroll>
                    <div className='mainDescriptionContainer'>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce>
                            <div className='mainDescription'>
                            {t('withdraw_part_one')}
                            {"\n\n"}
                            {t('withdraw_part_three')}
                            {"\n\n"}
                            {t('withdraw_part_two')}
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}